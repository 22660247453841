.Footer{
    display: flex;
    position: relative;
    justify-content: space-around;
    width: 100%;
    padding: 2rem 0;
    color: white;
    background-color: rgba(19, 19, 19, 0.7);
}
.Left{
    height: 100%;
    display: flex;
    flex-direction: column;
}
.Location{
    display: flex;
    flex-direction: row;
    margin-bottom: 1.5rem;
}
.Icon{
    font-size: 1.3rem;
    margin-right: 1rem;
    color: white;
}
.Footer p{
    font-size: 1.2rem;
}
.Right{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 30%;
}
.Right div{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

.Copyright{
    position: absolute;
    bottom: 2%;
}
@media screen and (max-width: 700px){
    .Footer{
        flex-direction: column;
        padding: 3rem 2rem;
    }
    .Right{
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .Location{
        margin-bottom: 0;
    }
    .Right p{
        text-align: start;
    }
    .Right div{
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 1rem;
    }
}

