.Nav {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 17vh;
    margin: 0 3%;
    cursor: pointer;

}
.Logo{
    font-family: "Lato";
    font-weight: 500;
    font-style: italic;
    font-size: 3rem;
    line-height: 40px;
    letter-spacing: -6px;
}
.Icons{
    display: flex;
    justify-content: space-around;
    width: 10%;
    font-weight: bolder;

}

.NavList{
    position: absolute;
    width: 25%;
    height: 100vh;
    top: 0;
    left: -3.3%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 1;
    margin: 0;
    padding: 1rem;

}
.NavList li{
    list-style: none;
    margin: 3rem 0;
    

}
.NavList a{
    text-decoration: none;
    color: white;
    font-family: "Montserrat";
    font-size: 1.2rem;
    cursor: pointer;
}
.NavList a:hover{
    border-bottom: 1px solid grey;
    transition: ease-in-out 2s;

}
.NavList.active{
    display: none;

}
.Cart{
    position: absolute;
    right: -3.2%;
    top: 0;
    height: 100vh;
    width: 25%;
    background-color: whitesmoke;
    z-index: 1;
    padding: 1rem;
    box-shadow: -3px 0px 100px 7px black;
    overflow-x: hidden;
}
.CartNav{
    display: flex;
    width: 100%;
    height: 15%;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    margin: 20px 0;
    
}
.CartNav p{
    font-size: large;
    font-family: "Roboto";
    margin-left: 20px;
}
.CartInfo{
    height: 70%;
    font-family: "Lato";
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
}
.CartClose{
    margin: 15px 20px;
}

@media screen and (max-width: 850px){
    .NavList, .Cart{
        width: 60%;
        left: -5.5%;
    }
    .NavList a{
        font-size: 1.4rem;
    }
    .Cart{
        width: 60%;
        right: -5.5%;
    }
}

@media screen and (max-width: 500px){
    .Nav {
        height: 12%;
        margin: 0 5%;
    }
    .Logo{
        
        font-size: 2.5rem;
        letter-spacing: -7px;
    }
    .Icons{
        width: 20%;
        justify-content: space-between;
    }
    .NavList, .Cart{
        width: 80%;
        left: -5.5%;
    }
    
}

