.About{
    display: flex;
    flex-direction: column;
    width: 100vw;
    font-family: "Roboto";
}
.Brand, .Owner{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 90%;
    margin: 1cm 0;
}
.Brand .Profile, .Owner .Profile{
    display: flex;
    flex-direction: column;
    flex-basis: 30%;
    
}
.Brand p, .Owner p{
    text-align: justify;
    font-size: large;
    line-height: 30px;
    
}
strong{
    font-size: x-large;
    font-family: 'Lato';
}
img{
    width: 30%;
    height: 30%;
}
@media screen and (max-width: 815px){
    .Brand, .Owner{
        flex-direction: column;
        margin: 1rem 2rem;
        width: 80%;

    }
    .Brand p, .Owner p{
        text-align: justify;
        font-size: larger;
        line-height: 30px;
    }
    .Brand img{
        display: none;
    }
    .Owner img{
        width: 60%;
        height: 60%;
        margin: 0 20%;
    }
    
}